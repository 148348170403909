<template>
  <v-container fluid class="main-container">
    <v-row>
      <v-col cols="12">
        <div class="float-left">
          <img src="" alt="logo1" />
        </div>
        <div class="float-right">
          <img src="" alt="logo2" onclick="x" />
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <h1>理解度テスト</h1>
    </v-row>
    <v-row class="mt-4">
      <v-col
        :class="`font-weight-bold mt-6 mb-3 learning-result ${resultClass}`"
        cols="12"
      >
        <div class="ml-2" v-if="isResultAllCorrect">
          <strong>既定点未達のため、再受講お願いします。</strong>
          <br />オフコンペ正規取扱店更新のための問題にご回答いただき、ありがとうございます。
        </div>
        <div class="ml-2" v-else>
          <strong>既定点未達のため、再受講お願いします。</strong>
          <br />オフコンペ正規取扱店更新のための問題にご回答いただき、ありがとうございます。
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-7">
      <p>
        <span class="text-danger">採点結果</span>
        {{
          `${result.questionCount} 問中 ${result.correctCount} 正解 ${correctPercentage} 点`
        }}
      </p>
    </v-row>
    <v-row>
      <question-result-group></question-result-group>
    </v-row>
    <div id="learning-form1">
      <div id="qlist" class="ml-2 mr-2 mt-5">
        <p class="text-center learning-thanks">
          ご協力いただき、ありがとうございます。
        </p>
        <v-btn
          width="100%"
          height=""
          class="mt-2 mb-2 submit-btn learning-close"
          @click="toHome"
        >
          元の画面に戻る
        </v-btn>
      </div>
    </div>
    <router-view />
  </v-container>
</template>

<script>
import QuestionResultGroup from "@/components/QuestionResultGroup.vue";
import { createNamespacedHelpers } from "vuex";
import { result } from "@/stores/result/getters";
import { ACTION_GET_RESULTS } from "@/stores/result/actions";
const { mapGetters, mapActions } = createNamespacedHelpers("result");
export default {
  name: "Result",
  components: { QuestionResultGroup },
  data() {
    return {
      shopId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters({
      result: result,
    }),
    isResultAllCorrect() {
      return this.result.correctCount === this.result.questionCount;
    },
    correctPercentage() {
      return Math.ceil(
        (this.result.correctCount / this.result.questionCount) * 100
      );
    },
    resultClass() {
      return this.isResultAllCorrect
        ? "light-green lighten-1"
        : "alert-warning";
    },
  },
  created() {
    this.getResult(this.shopId);
  },
  mounted() {},
  methods: {
    ...mapActions({
      getResult: ACTION_GET_RESULTS,
    }),
    toHome() {
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/result";
</style>
