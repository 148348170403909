  <template>
  <div id="question-container">
    <div
      class="question-group mt-6"
      :key="title"
      v-for="(questions, title) in result.questions"
    >
      <h3 class="question--outer-header">
        <img src="/images/h31.gif" alt="" />
        {{ `${title} 'について 全${questions.length}問` }}
      </h3>
      <hr style="display: block" />
      <question-result
        v-for="question in questions"
        :key="question.id"
        :question="question"
        :answers="question.answers"
      ></question-result>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { result } from "@/stores/result/getters";
import QuestionResult from "./QuestionResult.vue";
const { mapGetters } = createNamespacedHelpers("result");

export default {
  name: "QuestionResultGroup",
  components: { QuestionResult },
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters({
      result: result,
    }),
  },
  methods: {},
};
</script>
