  <template>
  <div
    :class="
      isQuestionCorrect
        ? 'mt-5 context learning-ok'
        : 'mt-5 context learning-ng'
    "
  >
    <h5 class="question--header">
      {{
        `Q${question.id}.の結果 ${isQuestionCorrect ? "正解" : "不正解"} ${
          question.description
        }`
      }}
    </h5>
    <div class="mt-6 pb-1">
      <p class="p-1 ml-3">あなたが選んだ答え</p>
      <p class="ml-3 mt-n3" v-for="answer in answers" :key="answer.answer_id">
        ・{{ answer.answer }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionResult",
  data() {
    return {};
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isQuestionCorrect() {
      return this.question.correct === "1" && this.question.ng_cnt === "0";
    },
  },
  methods: {},
};
</script>
